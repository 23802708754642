import { IoIosArrowDroprightCircle } from "react-icons/io";
import { GrPlan } from "react-icons/gr";
import { TfiStatsUp } from "react-icons/tfi";
import { MdNotInterested } from "react-icons/md";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const iconMap = {
    GrPlan: <GrPlan />,
    TfiStatsUp: <TfiStatsUp />,
    MdNotInterested: <MdNotInterested />
};

// Data for plans, sections, and features
const programData = {
    hero: {
        title: "Why Settle for Generic When You Can Go Personalized?",
        description: "Every body is unique, and so is every fitness journey. Progress, adaptability, and lifestyle vary from person to person—there’s no such thing as a one-size-fits-all solution! To help you achieve your goals faster and maximize your time and effort, we’ve designed a truly personalized program tailored to your specific needs, aspirations, lifestyle, and schedule.",
        question: "Why you should go for this?"
    },
    reasons: [
        {
            title: "Structured Plan",
            description:
                "Without a clear plan, progress can feel uncertain. A structured program ensures each step is purposeful, helping you achieve goals like mastering calisthenics skills, building muscle, improving flexibility, or enhancing overall fitness.",
            icon: "GrPlan",
            color: "bg-indigo-500",
        },
        {
            title: "Break Through Plateaus",
            description:
                "It’s natural to hit a plateau in your training, but breaking a plateau isn’t just about working harder—it’s about working smarter, programming better",
            icon: "TfiStatsUp",
            color: "bg-green-500",
        },
        {
            title: "No Excuses",
            description:
                "Workout anytime, anywhere, with or without equipment. The plan is tailored to the equipment you have (home, gym, or outdoors) and aligned with work-life balance, travels.",
            icon: "MdNotInterested",
            color: "bg-yellow-500",
        },
    ],
    steps: [
        {
            title: "Expert Consultation",
            description:
                "Understand your fitness level, goals, and lifestyle with a one-on-one consultation. Tailor workouts to your goals, resources, and schedule.",
        },
        {
            title: "Receive Your Plan",
            description:
                "Get a detailed weekly plan with video tutorials, reps/sets, and adjustments based on feedback.",
        },
        {
            title: "Ongoing Feedback",
            description:
                "Ensure correct form, track progress, and fine-tune your plan based on your body’s response.",
        },
    ],
    plans: {
        basic: {
            name: "Basic Program",
            features: [
                {
                    header: "Workout Personalization",
                    feature: "Customized upfront, updated weekly as per your progress"
                },
                {
                    header: "Form Feedback",
                    feature: "Weekly review of videos sent for form-correction"
                },
                {
                    header: "Coaching Calls",
                    feature: "One Session at the start of each month/term"
                },
                {
                    header: "Skill Development",
                    feature: "Only strength work included in Programs"
                }
            ],
            pricing: [
                { workouts: "12 workouts (1 month)", price: "₹3499", save: "" },
                { workouts: "24 workouts (1 month)", price: "₹4999", save: "" },
                { workouts: "36 workouts (3 months)", price: "₹8999", save: "1498" },
                { workouts: "72 workouts (3 months)", price: "₹12499", save: "2498" },
            ],
        },
        pro: {
            name: "Pro Program",
            features: [
                {
                    header: "Workout Personalization",
                    feature: "Fully dynamic, evolves weekly based on progress and feedback"
                },
                {
                    header: "Form Feedback",
                    feature: "Daily review of videos sent for form-corrections"
                },
                {
                    header: "Coaching Calls",
                    feature: "Weekly one-on-one sessions for education, progress updates, and motivation"
                },
                {
                    header: "Skill Development",
                    feature: "Live coaching for advanced skills (arm balancing, levers, etc.) in weekly calls"
                }
            ],
            pricing: [
                { workouts: "12 workouts (1 month)", price: "₹5999" },
                { workouts: "24 workouts (1 month)", price: "₹7999" },
                { workouts: "36 workouts (3 months)", price: "₹14999", save: "2998" },
                { workouts: "72 workouts (3 months)", price: "₹19999", save: "3998" },
            ],
        },
    },
    addOns: [
        {
            title: "Nutrition Plan",
            description: "Tailor your diet to fuel your goals and recovery.",
            price: "₹3000/month",
        },
        {
            title: "Physiotherapy Consultation",
            description: "Address pain, imbalances, or past injuries with expert guidance.",
            price: "₹3000/month",
        },
    ],
    declarations: [
        "The 1-month plan is valid for up to 2 months; the 3-month plan for up to 6 months, to adjust your travel or health issues",
        "Nutrition and physiotherapy sessions are additional paid services.",
        "One-on-one sessions are 30 minutes long.",
        "No refunds or adjustments after payment.",
        "Slots are limited; if full email us to join the waitlist.",
    ],
    keys: ["Workout", "Price"],
    services: {
        title: "How does this work?",
        steps: [
            {
                title: "Expert Consultation (with Srushti Shah)",
                description: "Our one-on-one consultation is designed to understand you—your fitness level, strengths, weaknesses, and lifestyle along with your goals",
                butllets: [{
                    title: "Premium Plan",
                    description: "Includes Weekly 1-on-1 sessions to train together, learn new skills, and dive deeper into fitness and technique fundamentals"
                }
                ],
            },
            {
                title: "Receive Your Plan",
                description: "Receive a detailed and meticulously planned workout schedule for the entire week with video tutorials for every exercise, specified reps and sets and Rest time guidance to ensure progress"
            },
            {
                title: "Feedback",
                description: "We don’t just give you a plan and leave you—we believe feedback is essential for real progress. You only receive the next workout, when you have sent us the feedback from the previous. Here’s why it matters:",
                bullets: [
                    {
                        title: "Ensures Correct Form",
                        description: "Share your workout videos with us for detailed feedback on form and effort, preventing mistakes and ensuring you train safely and efficiently."
                    },
                    {
                        title: "Progress Tracking",
                        description: "Your progress is constantly measured, ensuring we adjust based on how your body responds."
                    },
                    {
                        title: "Tailored Adjustments",
                        description: "Everyone reacts differently, so we listen to your body and fine-tune the plan to keep you moving forward."
                    }
                ]
            },
            {
                title: "Provides Accountability, Motivation & Instant Support",
                description: "With your coach monitoring your progress, you'll remain committed and consistent. Stay connected for continuous guidance through chat, weekly check-ins, and constant reminders to keep you focused and progressing toward your goals."
            },
            {
                title: "Holistic Support with Our Add-On Services",
                description: "Our goal is not just to teach you exercises, but to help you understand why and how they work, enabling you to design your own workouts confidently. You can choose to opt for added services",
                bullets: [
                    {
                        title: "Nutrition Guidance",
                        description: "Tailor your diet to fuel goals and support recovery at Rs. 3000/- from certified nutritionists",
                    },
                    {
                        title: "Physiotherapy Insights",
                        description: "Address pain, imbalances, or past injuries with expert movement strategies at Rs. 3000/- from expert doctors",
                    }
                ]
            }
        ]
    },
    registrationLink: "https://forms.gle/YntSm6QjPm2nfgGt5"

};
const settings = {
    dots: true, // Show dots for navigation
    infinite: false, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 2, // Show one slide at a time on mobile
    slidesToScroll: 1, // Scroll one slide at a time on mobile
    autoplay: false, // Enable autoplay
    centerMode: true, // Center the active plan
    centerPadding: "", // Show part of the next item on mobile
    focusOnSelect: true, // Allow selecting the centered item
    arrows: true, // Disable arrows
    swipe: true,
    responsive: [
        {
            breakpoint: 700, // For tablet and desktop
            settings: {
                slidesToShow: 1, // Show 2 items side by side
                slidesToScroll: 1, // Scroll one at a time
                centerMode: true, // No centering, both items visible
                centerPadding: "30px", // No padding between items
            },
        },
    ],
};
// Component rendering dynamically
const PersonalizedPlansPageComponent = ({ theme }) => {
    return (

        <section class={`body-font ${theme.textFocus} ${theme.background}`}>

            <div class="lg:w-4/5 mx-auto text-center py-24">
                <h1 class={`text-4xl font-extrabold leading-tight mb-12 ${theme.textFocus}`}>
                    Personalised Workout Plans
                </h1>
                {/* Hero Section */}
                <header class="text-center mb-20 px-5 ">
                    <h1 class={`text-3xl font-semibold leading-tight mb-6 ${theme.textFocus}`}>
                        {programData.hero.title}
                    </h1>
                    <p class="text-lg leading-relaxed mx-auto">{programData.hero.description}</p>
                </header>

                <section class="mb-20 px-5 ">
                    <h1 class={`text-3xl text-center font-semibold leading-tight mb-6 ${theme.textFocus}`}>
                        {programData.hero.question}
                    </h1>
                    <div class="grid md:grid-cols-3 gap-8">

                        {programData.reasons.map((reason, index) => (
                            <div
                                key={index}
                                class={`p-6 ${theme.backgroundSecondary} shadow-lg rounded-lg hover:shadow-xl transition`}
                            >
                                <div class="flex">
                                    <span
                                        class={`inline-block ${reason.color} text-white p-3 rounded-full mb-4`}
                                    >
                                        {iconMap[reason.icon]}</span>
                                    <h3 class="text-xl font-semibold m-2">{reason.title}</h3>

                                </div>
                                <p class={`${theme.textFocus}`}>{reason.description}</p>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Plans Section */}
                <section class="mb-10">
                    <h2 class={`text-3xl font-semibold text-center mb-8 ${theme.textFocus}`}>
                        Choose Your Plan
                    </h2>
                    <Slider {...settings} className="!leading-none flex items-stretch">
                        {Object.entries(programData.plans).map(([key, plan]) => (
                            <div key={key} className="p-4 w-full justify-between h-full">
                                <div
                                    className={`p-5 ${theme.backgroundSecondary} rounded-lg shadow-lg transform hover:scale-105 transition h-full`}
                                >
                                    {/* Plan Header */}
                                    <div className="flex flex-col items-center mb-6 text-2xl font-bold">
                                        {plan.name}
                                    </div>
                                    {/* Features List */}
                                    <ul className={`space-y-4 text-left mb-6 h-full ${theme.textFocus}`}>
                                        {plan.features.map((feature, index) => (
                                            <li key={index} className="flex items-start">
                                            {/* Fixed width container for the icon */}
                                            <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center p-1">
                                                <IoIosArrowDroprightCircle className="w-2/3 h-2/3" />
                                            </div>
                                            {typeof feature === "object" && feature !== null ? (
                                                <div className="p-1">
                                                    <p className="font-semibold p-1">{feature.header}</p>
                                                    <p>{feature.feature}</p>
                                                </div>
                                            ) : (
                                                <span>{feature}</span>
                                            )}
                                        </li>
                                        ))}
                                    </ul>
                                    {/* Pricing Section */}
                                    <div className="rounded-lg shadow-inner p-4">
                                        <h4 className="text-lg font-semibold mb-4 text-center">Pricing</h4>
                                        <div className="grid grid-cols-2 gap-4 text-sm font-medium">
                                            {plan.pricing.map((price, index) => (
                                                <div
                                                    key={index}
                                                    className={`p-4 rounded-md shadow-sm ${theme.background} hover:drop-shadow-xl transition`}
                                                >
                                                    <p className="text-center font-bold">{price.workouts}</p>
                                                    <p className="text-center text-lg">{price.price}</p>
                                                    {price.save && (
                                                        <p className="text-center text-xs text-normal text-green-600">
                                                            Save ₹{price.save}
                                                        </p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* CTA Button */}
                                    <div className="text-center mt-6">
                                        <Link
                                            to={programData.registrationLink}
                                            className="bg-brand-500 text-white px-6 py-3 rounded-lg hover:bg-brand-600 flex items-center justify-center"
                                        >
                                            Choose {plan.name}
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                className="w-4 h-4 ml-2"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </section>


                {/* Add-ons Section */}
                <section class="mb-10 px-5 ">
                    <h2 class={`text-xl font-semibold text-center mb-4 ${theme.textFocus}`}>Add-ons</h2>
                    <div class="grid grid-cols-2 gap-4 text-sm font-medium">
                        {programData.addOns.map((addOn, index) => (
                            <div
                                key={index}
                                class={`p-3 ${theme.backgroundSecondary} rounded-lg shadow-lg hover:shadow-xl transition`}
                            >
                                <p class="text-lg font-semibold mb-2">
                                    {addOn.title} @{addOn.price}
                                </p>
                                <p class={` ${theme.textNormal}`}>{addOn.description}</p>
                            </div>
                        ))}
                    </div>
                </section>

                {/* How Services Work Section */}
                <section className="px-5 ">
                    <h2 className={`text-3xl font-semibold text-center mb-8 mt-8 ${theme.textFocus}`}>
                        {programData.services.title}
                    </h2>

                    <div className="max-w-4xl mx-auto justify-top">
                        {programData.services.steps.map((details, index) => (
                            <div key={index} className={`p-3 rounded-lg shadow-lg mb-3 ${theme.textFocus} ${theme.backgroundSecondary} transition-transform transform hover:scale-105`}>
                                <div className="flex ml-4 mt-2 mb-2">
                                    {/* Step Counter */}
                                    <span className={`${theme.background} rounded-full w-8 h-8 flex items-center justify-center font-bold mr-2`}>
                                        {index + 1}
                                    </span>     <span className="text-lg text-left font-bold">{details.title}</span>
                                </div>
                                <p className="ml-4 text-sm text-left">{details.description}</p>
                                {details.bullets && details.bullets.length > 0 && (
                                    <ul className="mt-4 space-y-4 list-disc text-left">
                                        {details.bullets.map((bullet, index2) => (
                                            <li key={index2} className={`ml-10 text-sm`}>
                                                <span className="font-semibold">{bullet.title} : </span>{bullet.description}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </section>


                {/* Declarations Section */}
                <section className="px-5 ">
                    <h2 className={`text-3xl font-semibold text-center mb-6 mt-10 ${theme.textFocus}`}>
                        Important Declarations
                    </h2>
                    <div className={`${theme.backgroundSecondary} p-8 rounded-lg shadow-lg text-left`}>
                        <ul className="space-y-4 list-disc">
                            {programData.declarations.map((item, index) => (
                                <li key={index} className={``}>
                                    <span>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default PersonalizedPlansPageComponent;

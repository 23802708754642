import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { TestimonialComponent } from "../../components/TestimonialsComponent";
import { FAQComponent } from "../../components/FAQComponent";
import ErrorPage from "../errorPage/ErrorPage";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";
import SimpleProgramPageComponent from "../../components/SimpleProgramPageComponent";
import PersonalizedPlansPageComponent from "../../components/PersonalizedPlansPageComponent";

const getProgram = (programDisplayId, appContext) => {
    return appContext.programsPageData.programsSection.programs.find(program => program.displayId === programDisplayId);
}

export default function ProgramPage() {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const { programDisplayId, variantDisplayId } = useParams();
    const navigate = useNavigate();
    const [variant, setVariant] = React.useState(getProgram(programDisplayId, appContext)?.variants.find(variant => variant.displayId === variantDisplayId));
    const [program, setProgram] = React.useState({
        ...getProgram(programDisplayId, appContext),
        ...variant
    });
    const [variantsConfiguration, setVariantsConfiguration] = React.useState([]);

    const updateCurrentVariant = (variant) => {
        setVariant(variant);
        setProgram({
            ...getProgram(programDisplayId, appContext),
            ...variant
        });
        navigate(`/programs/${programDisplayId}/${variant.displayId}`);
    }

    useEffect(() => {
        if (!variant || !program) {
            navigate("/error");
        } else {
            setVariantsConfiguration(appContext.programsPageData.programsSection.variantsConfiguration.filter(variantConfiguration => {
                return variant[variantConfiguration.key] || variant.auxiliaryData[variantConfiguration.key];
            }))
        };
    }, [programDisplayId, variantDisplayId]);

    return (
        !variant || !program ? <ErrorPage /> :
            program.programType === "personalisedProgram" ? PersonalizedPlansPageComponent({theme}) :
            SimpleProgramPageComponent({theme, program, variant, updateCurrentVariant, variantDisplayId})
    );
}     

